import { CheckoutRoutes } from "../action-constants/checkout-constants";

const dataLayerVarName = "adobeDataLayer";
const omnitureLocalStorage = "com.adobe.reactor.dataElementCookiesMigrated";

const FnpIND = {
  brand: "fnp ind-m",
  country: "India",
  guestLogin: "guest",
  loggedInLogin: "logged in",
  site: "react",
  catalogId: "india",
  countryId: "IN",
};

const FnpSG = {
  brand: "fnp sg-m",
  country: "Singapore",
  loginStatus: "guest",
  catalogId: "sgp",
  countryId: "SG",
};

const FnpUAE = {
  brand: "fnp uae-m",
  country: "UAE",
  loginStatus: "guest",
  catalogId: "uae",
  countryId: "UAE",
};

const FnpDesktopIND = {
  brand: "fnp ind",
  country: "India",
  guestLogin: "guest",
  loggedInLogin: "logged in",
  site: "react",
  catalogId: "india",
  countryId: "IN",
};

const FnpDesktopSG = {
  brand: "fnp sg",
  country: "Singapore",
  loginStatus: "guest",
  catalogId: "sgp",
  countryId: "SG",
};

const FnpDesktopUAE = {
  brand: "fnp uae",
  country: "UAE",
  loginStatus: "guest",
  catalogId: "uae",
  countryId: "UAE",
};

const channel = {
  PLP: "plp",
  HOME: "home",
  PDP: "pdp",
  SEARCH: "search result",
  CART: "cart",
  GIFT_FINDER: "giftfinder",
  MY_ACCOUNT: "myaccount",
  ERROR_404: "error404",
  CHECKOUT: "checkout",
  ORDER_NOT_CONFIRM: "ordernotconfirm",
  INFO: "info",
  EXPERIENCES: "experiences",
  TESTIMONIALS: "testimonials",
};
const eventCategories = {
  PRODUCT_VIEW: "product view",
  PRODUCT_LISTING: "product listing",
  SEARCH: "search",
  CART_VIEW: "cart view",
  CART_REMOVE: "cart remove",
  CHECKOUT_1: "Checkout 1",
  CHECKOUT_2: "Checkout 2",
  CHECKOUT_3: "Checkout 3",
  PAYMENT_METHOD: "payment method",
  PURCHASE: "Purchase",
  MESSAGE_ON_CARD: "free message card",
  PAYMENT_FAILED: "Transaction Failed",
};
const paths = {
  EVENT: "event",
  FNP_EVENT: "fnpevent",
  PAGE: "page",
  PRODUCT: "product",
  PRODUCT_LIST: "productString",
  CATEGORY: "category",
  CUSTOMER_DATA: "custdata",
  SEARCH: "search",
  CART: "cart",
  LINK: "link",
  PAYMENT: "payment",
  TRANSACTION: "transaction",
  COUPON: "coupon",
  GATE: "gate",
};

const checkoutDirectCalls = {
  MOBILE: "generic-vp",
  DESKTOP: "generic-vp-dkp",
};

const pageCategories = {
  PRODUCT: "product",
  PRODUCT_LIST: "category",
  INFO: "info",
  HOME: "home",
  SEARCH: "search",
  CART: "cart",
  GIFT_FINDER: "giftfinder",
  ERROR_404: "error404",
  DELIVERY_ADDRESS: "deliveryaddress",
  PAYMENT_OPTIONS: "paymentOptions",
  EXPERIENCES: "experiences",
  TESTIMONIALS: "testimonials",
};
const loggedInStatus = {
  LOGGED_IN: "logged in",
  GUEST: "guest",
};

const checkoutAttributes = {
  [CheckoutRoutes.LOGIN]: {
    pageName: "checkout-login",
    pageCategory: eventCategories.CHECKOUT_1,
    event: eventCategories.CHECKOUT_1,
  },
  register: {
    pageName: "checkout-register",
    pageCategory: "checkoutRegister",
    event: eventCategories.CHECKOUT_1,
  },
  [CheckoutRoutes.DELIVERY_ADDRESS]: {
    pageName: "delivery-address",
    pageCategory: eventCategories.CHECKOUT_2,
    event: eventCategories.CHECKOUT_2,
  },
  [CheckoutRoutes.APPLY_COUPON]: {
    pageName: "delivery-address",
    pageCategory: eventCategories.CHECKOUT_2,
    event: eventCategories.CHECKOUT_2,
  },
  [CheckoutRoutes.MESSAGE_ON_CARD]: {
    pageName: "delivery-address",
    pageCategory: eventCategories.CHECKOUT_2,
    event: eventCategories.MESSAGE_ON_CARD,
  },
  [CheckoutRoutes.CHANGE_DELIVERY]: {
    pageName: "delivery-address",
    pageCategory: eventCategories.CHECKOUT_2,
    event: eventCategories.CHECKOUT_2,
  },
  [CheckoutRoutes.ADD_NEW_ADDRESS]: {
    pageName: "delivery-address",
    pageCategory: eventCategories.CHECKOUT_2,
    event: eventCategories.CHECKOUT_2,
  },
  [CheckoutRoutes.EDIT_DELIVERY]: {
    pageName: "delivery-address",
    pageCategory: eventCategories.CHECKOUT_2,
    event: eventCategories.CHECKOUT_2,
  },
  [CheckoutRoutes.PAYMENT_OPTIONS]: {
    pageName: "payment-options",
    pageCategory: pageCategories.PAYMENT_OPTIONS,
    event: eventCategories.CHECKOUT_3,
  },
  [CheckoutRoutes.RETRY_PAYMENT]: {
    pageName: "payment-retry-options",
    pageCategory: eventCategories.CHECKOUT_3,
    event: eventCategories.CHECKOUT_3,
  },
  [CheckoutRoutes.ORDER_CONFIRMATION]: {
    pageName: "order-confirmation",
    pageCategory: "confirmorder",
    event: eventCategories.PURCHASE,
  },
  [CheckoutRoutes.PAYMENT_FAILED]: {
    pageName: "payment-not-confirmed",
    pageCategory: "ordernotconfirm",
    event: eventCategories.PAYMENT_FAILED,
  },
};

const LOCATION_LOCK_EVENTS = {
  GATE_BUTTON_CLICKED: "gate_button_clicked",
  GATE_PIN_CODE_CLICKED: "gate_pincode_clicked",
  GATE_PIN_CODE_SELECTED: "gate_pincode_selected",
  GATE_DONT_KNOW_PIN_CODE_CLICKED: "gate_dontknowpincode_clicked",
  GATE_DONT_KNOW_PIN_CODE_SELECTED: "gate_dontknowpincode_selected",
  GATE_COUNTRY_CLICKED: "gate_country_clicked",
  GATE_COUNTRY_SELECTED: "gate_country_selected",
  GATE_CITY_CLICKED: "gate_city_clicked",
  GATE_CITY_SELECTED: "gate_city_selected",
  GATE_CONTINUE_CLICKED: "gate_continue_clicked",
  GATE_NON_SERVICEABLE_CLICKED: "gate_nonserviceable_clicked",
  GATE_VIEW_AVAILABLE_GIFTS_CLICKED: "gate_viewavailablegifts_clicked",
};

const SEARCH_SUGGESTION_EVENTS = {
  SEARCH_SUGGESTION_CLICKED: "Searchsuggestion_Clicked",
  NO_SEARCH_RESULTS: "nosearchresults",
};

const GATE_POPUP_TRACKING = {
  AUTO_GATE_POPUP_VIEWED: "auto gate popup viewed",
  PINCODE_UPDATED: "pincode updated",
  PINCODE_RETAINED: "pincode retained",
  AUTO_CONTINUE_SHOPPING_CLICKED: "auto continue shopping clicked",
  MANUAL_CONTINUE_SHOPPING_CLICKED: "manual continue shopping clicked",
  AUTO: "auto",
  MANUAL: "manual",
  AUTO_GREY_AREA_CLICKED: "auto grey area clicked",
  MANUAL_GREY_AREA_CLICKED: "manual grey area clicked",
  AUTO_GATE_CLOSE: "auto gate close",
  MANUAL_GATE_CLOSE: "manual gate close",
};

export {
  omnitureLocalStorage,
  dataLayerVarName,
  FnpIND,
  FnpSG,
  FnpUAE,
  FnpDesktopIND,
  FnpDesktopSG,
  FnpDesktopUAE,
  channel,
  eventCategories,
  paths,
  pageCategories,
  loggedInStatus,
  checkoutAttributes,
  checkoutDirectCalls,
  LOCATION_LOCK_EVENTS,
  SEARCH_SUGGESTION_EVENTS,
  GATE_POPUP_TRACKING,
};
