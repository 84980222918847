import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { callWidgetApiForPincode } from "./common";
/**
 * A hook to track the current value of any variable you send as parameter
 *
 * @param {object} value any value which you need to track
 *
 * @returns {object} current Ref value
 */
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * This hook is used to set the currencies list and selected currency to the window for getWidget script.
 */
function useSetCurrencyDetailsToWindow() {
  const currenciesList = useSelector((state) => state.currenciesList.currencies);
  const { selectedCurrency } = useSelector((state) => state.appData);
  useEffect(() => {
    if (currenciesList.length > 0 && selectedCurrency) {
      window.currenciesData = {
        currenciesList,
        selectedCurrency,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenciesList, selectedCurrency]);
}

/**
 * This hook is used to reload the dooda widgets on change of pincode by calling widget api with latest pincode.
 */
function useReloadWidgetsPincodeChange() {
  const locationLockLists = useSelector((state) => state.locationLockLists);
  useEffect(() => {
    /**
     * This method call the dooda api and fetches the widgets on pincode update.
     *
     */
    const callGetWidgetApi = async () => {
      const pincode = window.dSite ? locationLockLists.selectedPinCode : locationLockLists.finalSelectedPinCode;
      // on initial page load this function runs by widget helper script. so on pincode change only it should run
      if (pincode || (document.readyState === "complete" && !pincode)) {
        await callWidgetApiForPincode(locationLockLists.finalSelectedPinCode);
      }
    };
    callGetWidgetApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationLockLists.finalSelectedPinCode]);
}

export default usePrevious;
export { useSetCurrencyDetailsToWindow, useReloadWidgetsPincodeChange };
