import React from "react";
import Head from "next/head";
import HTMLReactParser from "html-react-parser";
import { useSelector } from "react-redux";
import getConfig from "next/config";

/**
 * This component parses all the common page header data created in CMS
 *
 * @returns {React.ReactElement} jsx for the page header info.
 */
const CMSPageHead = () => {
  const pageHead = useSelector((state) => state.commonPageData.pageHead);
  const userDetails = useSelector((state) => state.userDetails);
  const { configData } = useSelector((state) => state?.appConfigs || {});
  const { appConfigs } = useSelector((state) => state || {});
  const cookieDomain = configData?.default?.cookieDomain || ".fnp.com";
  const domainId = cookieDomain.substring(1);
  const isUserLoggedIn = userDetails?.checkLoginInfo?.loggedin;
  const headString = Object.values(pageHead).reduce((str, data) => str + data, "");
  const { publicRuntimeConfig } = getConfig();

  return (
    <Head>
      {HTMLReactParser(
        `<script>var domainValue= '${domainId}'; var geoId = '${appConfigs.catalogId}';var isLoggedIn = ${isUserLoggedIn};var gateWayURL = '${publicRuntimeConfig.R2_CLIENT_API_GATEWAY}'</script>`,
      )}
      {headString && HTMLReactParser(headString)}
    </Head>
  );
};

export default CMSPageHead;
