import { useMemo } from "react";
import { parseCookies } from "nookies";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import useCommonOmnitureData from "./useCommonOmnitureData";
import useOmnitureSetup from "./useOmnitureSetup";
import { COUNTRIES, LocationLockStaticConstants } from "../../common/constants";
import { RoutePageConstants, getRoutePageName } from "../../../src/utils/common";
import { GATE_POPUP_TRACKING } from "../../../src/omniture/omniture-consts";

/**
 * Home page omniture setup
 */
export default function useCommonOmnitureSetup() {
  const router = useRouter();
  const { rootGeoId, catalogId, checkLoginInfo } = useCommonOmnitureData();
  const { lastSelectedPincode, selectedIntlCity } = parseCookies();
  const { autoGatePopupConfig, isAutoGateConfigReceived } = useSelector((store) => store.locationLockLists);
  const { isAutoGateEnabledOnDesktopHome, isAutoGateEnabledOnMobileHome } = autoGatePopupConfig || {};
  const isHomePage = getRoutePageName(router?.pathname) === RoutePageConstants.HOME;

  const gateData = {
    category: {
      country:
        catalogId !== COUNTRIES.INDIA
          ? LocationLockStaticConstants.OUTSIDE_INDIA
          : LocationLockStaticConstants.WITHIN_INDIA,
      countryname: catalogId,
      city: selectedIntlCity || "",
      pincode: lastSelectedPincode || "",
    },
  };

  if ((isAutoGateEnabledOnDesktopHome || isAutoGateEnabledOnMobileHome) && isHomePage) {
    gateData.gate = { popUp: GATE_POPUP_TRACKING.AUTO_GATE_POPUP_VIEWED };
  }

  const omniData = useMemo(
    () => ({
      userDetails: {
        checkLoginInfo,
      },
      appConfigs: {
        rootGeoId,
        catalogId,
      },
      gateDetails: {
        gateData,
        lastSelectedPincode,
        selectedIntlCity,
        autoGatePopupConfig,
      },
      isAutoGateConfigReceived,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [catalogId, checkLoginInfo, rootGeoId, autoGatePopupConfig, isAutoGateConfigReceived],
  );

  useOmnitureSetup(omniData, [omniData?.isAutoGateConfigReceived, omniData?.userDetails?.checkLoginInfo]);
}
